import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import {
  GraphQLReactTable as TGraphQLReactTable,
  GraphQLReactTableProps,
} from '@asaprint/asap/components/ReactTable.js';
import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { COLUMN_ACTIONS, TOOLTIP_EDIT } from '@asaprint/asap/locales/client.js';
import { ADMIN_PHASES_CREATE_ROUTE, ADMIN_PHASES_EDIT_ROUTE, DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import {
  Phases_ChangePositionSave,
  Phases_Load,
} from '@asaprint/asap/routes/__authenticated/admin/phases/index.graphql';
import { Phases_MaxPositionLoad } from '@asaprint/asap/routes/__authenticated/admin/phases/index.graphql';
import {
  PhaseGroupFilter,
  Phases_ChangePositionSaveMutation,
  Phases_ChangePositionSaveMutationVariables,
  Phases_LoadQuery,
  Phases_MaxPositionLoadQuery,
  Phases_MaxPositionLoadQueryVariables,
  Phases_PhaseFragment,
  TimeEntriesProductCreative_LoadProductQuery,
} from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import Loading from '@engined/client/components/Loading.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { url } from '@engined/core/services/routes.js';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add, ArrowCircleDown, ArrowCircleUp, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Column, TableState } from 'react-table';

const logger = getLogger('routes/__authenticated/admin/phases/index');

const GraphQLReactTable = TGraphQLReactTable as unknown as React.NamedExoticComponent<
  GraphQLReactTableProps<Phases_PhaseFragment>
>;

interface OwnProps {}

type Props = OwnProps;

const emptyArray = [];
const emptyObject = {};
const defaultSortBy: TableState['sortBy'] = [
  { id: 'group', desc: false },
  { id: 'position', desc: false },
];

const mapData = (data: Phases_LoadQuery) => data?.phases?.edges.map((e) => e.node) || emptyArray;
const mapCount = (data: Phases_LoadQuery) => data?.phases?.pageInfo.count || 0;

const Page: React.FunctionComponent<Props> = () => {
  const { t } = useLocale();
  const { data, loading, error } = useQuery<Phases_MaxPositionLoadQuery, Phases_MaxPositionLoadQueryVariables>(
    Phases_MaxPositionLoad,
  );

  const maxPositions = useMemo(
    () =>
      data?.phaseGroups?.edges.reduce((acc, cur) => {
        acc[cur.node.id] = cur.node.phasesMaxPosition;
        return acc;
      }, {}) ?? emptyObject,
    [data],
  );

  const columns: Column<Phases_PhaseFragment>[] = useMemo(
    () => [
      {
        Header: t(COLUMN_ACTIONS),
        accessor: 'id',
        disableFilters: true,
        disableSorting: true,
        width: 50,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row: { original } }) => (
          <CenteredCell>
            <Tooltip title={t(TOOLTIP_EDIT)}>
              <IconButton component={RouterLink} to={url(ADMIN_PHASES_EDIT_ROUTE, { id: value })} size="small">
                <Edit />
              </IconButton>
            </Tooltip>
            <ChangePositionButton direction="up" phase={original} disabled={original.position === 1} />
            <ChangePositionButton
              direction="down"
              phase={original}
              disabled={original.position === maxPositions[original.group.id]}
            />
          </CenteredCell>
        ),
      },
      {
        Header: 'Pozícia',
        accessor: 'position',
        width: 50,
      },
      {
        Header: 'Skupina',
        accessor: 'group',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => <div>{value.name}</div>,
        sortByToGraphQLVariable: () => 'group.name',
        filterToGraphQLVariable: (value: string): PhaseGroupFilter => ({
          name: {
            ilike: `%${value}%`,
            unaccent: true,
          },
        }),
      },
      {
        Header: 'Názov',
        accessor: 'name',
        width: 200,
      },
      {
        Header: 'Farba',
        accessor: 'color',
        width: 50,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => (
          <Box>
            <Box
              component="span"
              width="1em"
              height="1em"
              display="inline-block"
              border={1}
              borderColor="#ccc"
              borderRadius={1}
              mr={2}
              bgcolor={`#${value}`}
            />
            #{value}
          </Box>
        ),
      },
      {
        Header: 'Fáza v  maloformátovom kalkulátore',
        accessor: 'calculatorName',
        width: 200,
      },
    ],
    [t, maxPositions],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faLayerGroup} /> Fázy produktu
          </>
        }
        actions={
          <Box display="flex" justifyContent="flex-end">
            <Button
              component={RouterLink}
              to={ADMIN_PHASES_CREATE_ROUTE}
              color="primary"
              variant="contained"
              startIcon={<Add />}
            >
              Nová fáza
            </Button>
          </Box>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <GraphQLReactTable
            id="admin-phases"
            columns={columns}
            defaultSortBy={defaultSortBy}
            query={Phases_Load}
            mapCount={mapCount}
            mapData={mapData}
            canPrint={false}
            canXLSXExport={false}
          />
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.PhasesManage)(Page);

interface Phase {
  id: string;
  updatedAt?: string;
  position: number;
  group: {
    id: string;
  };
}

interface ChangePositionButtonOwnProps {
  phase: Phase;
  direction: 'up' | 'down';
  disabled: boolean;
}

const ChangePositionButton: React.FunctionComponent<ChangePositionButtonOwnProps> = ({
  phase,
  direction,
  disabled,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [execute, { loading }] = useMutation<
    Phases_ChangePositionSaveMutation,
    Phases_ChangePositionSaveMutationVariables
  >(Phases_ChangePositionSave, {
    variables: {
      id: phase.id,
      updatedAt: phase.updatedAt,
      groupId: phase.group.id,
      position: direction === 'up' ? phase.position - 1 : phase.position + 1,
    },
    refetchQueries: ['Phases_Load'],
  });

  const onClick = useEventCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      await execute();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  });

  return (
    <Tooltip title={direction === 'up' ? 'Posunúť vyššie' : 'Posunúť nižšie'}>
      <span>
        <IconButton disabled={loading || disabled} onClick={onClick} size="small">
          {direction === 'up' ? <ArrowCircleUp /> : <ArrowCircleDown />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

ChangePositionButton.displayName = 'ChangePositionButton';

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  const result = await apollo.query<Phases_MaxPositionLoadQuery, Phases_MaxPositionLoadQueryVariables>({
    query: Phases_MaxPositionLoad,
  });
  return result.data;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Fázy produktu',
    },
  ],
  meta: ({ meta }: MetaFunctionArgs<TimeEntriesProductCreative_LoadProductQuery>) => {
    return {
      title: `Fázy produktu | Administrácia | ${meta.title}`,
    };
  },
};
